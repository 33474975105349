import React from "react";

function article_2() {
	return {
		date: "20 November 2023",
		title: "Desarrollo e Implementación de un Portal Educativo que mejore las operaciones orientadas a la educación y administración",
		description:
			"The internet has become the largest information medium of our time. Thanks to the development of tools that allow creating, manipulating, and sharing information, it has become an essential tool in current job performance. Therefore, the use of web applications has skyrocketed, not just as a necessity, but as a \"must-have\"",
		style: ``,
		keywords: [
			"AWS",
			"AMPLIFY",
			"NODE",
			"NoSQL"
		],
		link: "http://13.87.204.143/xmlui/handle/123456789/7947",
	};
}

const myArticles = [article_2];

export default myArticles;
