const SEO = [
	{
		page: "home",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Web", "Services", "Software", "Developer", "Graphics", "SQL", "NoSQL"],
	},

	{
		page: "articles",
		description:
			"A collection of the articles I have written and developed on programming.",
		keywords: ["Web", "Services", "Software", "Developer", "Graphics", "SQL", "NoSQL"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Web", "Services", "Software", "Developer", "Graphics", "SQL", "NoSQL"],
	},
];

export default SEO;
