const INFO = {
	main: {
		title: "portfolio",
		name: "Dilan Gómez Toaza",
		email: "dylan.javi13@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/Astrol1ght-bot?tab=repositories",
		linkedin: "https://www.linkedin.com/in/dilan-g%C3%B3mez-toaza-5ba824176/",
	},

	homepage: {
		title: "Developer and gaming enthusiast",
		description:
			"I am a backend developer passionate about software development. I am proficient in programming languages such as Python, C++, Java, C#, and JavaScript. Additionally, I have a strong interest in cybersecurity and lately i've been lifting heavy circles at the gym.",
	},

	projects: [
		{
			title: "MVC ORM APP",
			description:
				".net, c#, sql, orm",
			logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2yzrGckLT72I-39SSoQUzx_T14RYYfHasB1J_5Y0XL1XMZafbNt660zjjDLq-0Boaa40&usqp=CAU",
			linkText: "View Project",
			link: "https://github.com/Astrol1ght-bot/FundaVidaCoursesApp.git",
		},

		{
			title: "MyTasks ",
			description:
				"maui .net, framework 8, c#",
			logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJiB9YcvnXN0GJGqe4rh9jZvulaFG0IynmNUMWrdxHCA&s",
			linkText: "View Project",
			link: "https://github.com/Astrol1ght-bot/MyTasks",
		},

		{
			title: "MadEngineGL",
			description:
				"c++, opengl, glew, freeglut, ogldev",
			logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlzQPtLSA7-62foKEf2lgOOU0iIjl55O03d8jA_d_eIA&s",
			linkText: "View Project",
			link: "https://github.com/Astrol1ght-bot/MadEngineGL",
		},
	],
};

export default INFO;
